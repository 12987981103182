import React from 'react';
import { ChildrenInterface } from 'types';
import { MessageProvider } from './message';
import { ModalProvider } from './modals';
import { LogsProvider } from './logs';
import { WsProvider } from './ws';
import { ChargePointProvider } from './chargePoint';
import { ChargersProvider } from './chargers';
import { ClearLogsProvider } from './clearLogs';


export const StoreProvider: React.FC<ChildrenInterface> = ({ children }) => (
  <MessageProvider>
    <WsProvider>
      <ChargePointProvider>
        <ChargersProvider>
          <ModalProvider>
            <ClearLogsProvider>
              <LogsProvider>{children}</LogsProvider>
            </ClearLogsProvider>
          </ModalProvider>
        </ChargersProvider>
      </ChargePointProvider>
    </WsProvider>
  </MessageProvider>
);
